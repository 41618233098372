import { type SearchCategory, FilterPriceType } from '@kijiji/generated/graphql-types'
import { type TFunction } from 'next-i18next'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { type ApiLocale, API_LOCALE } from '@/domain/locale'

type CategoryNameProps = {
  t: TFunction
  apiLocale: ApiLocale
  category: SearchCategory
  priceType?: FilterPriceType
}

/**
 * Resolves the category name based on the provided properties.
 *
 * @param t - Translation function from useTranslation('srp')
 * @param apiLocale - Locale used for API (en_CA or fr_CA)
 * @param category - Search category details
 * @param priceType - Optional price type
 *
 * @returns {string} - The resolved category name
 *
 * @example
 * const name = resolveCategoryName({
 *   t: (key) => translations[key],
 *   category: { id: 1, localizedName: 'Electronics' },
 *   apiLocale: 'en_CA',
 *   priceType: PriceType.GiveAway
 * });
 * name -> "Free Listings"
 */
export const resolveCategoryName = ({
  t,
  category,
  apiLocale,
  priceType,
}: CategoryNameProps): string => {
  const categoryName = category.localizedName

  if (category.id !== ALL_CATEGORIES_ID_NUM) {
    return categoryName
  }

  if (priceType === FilterPriceType.GiveAway) {
    return t('seo.meta.free_listings')
  }

  // If the language is FR, then H1 should have "Toutes" before the localized name
  if (apiLocale === API_LOCALE.fr_CA) {
    return `${t('seo.meta.all')} ${category.localizedName.toLowerCase()}`
  }

  return categoryName
}
