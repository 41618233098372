import { FocusScope } from '@radix-ui/react-focus-scope'
import { ComponentProps, forwardRef } from 'react'
import { Drawer } from 'vaul'

import {
  DraggableDrawerBackdrop,
  DraggableDrawerChildren,
  DraggableDrawerContent,
  DraggableDrawerIndicator,
} from './styled'

export type DrawerDraggableProps = {
  children: React.ReactNode
  hasBackdrop?: boolean
  isContentScrollable?: boolean
  isOpen: boolean
  onCancel: () => void
  onClickIndicator?: () => void
  indicatorAriaLabel?: string
} & Pick<
  ComponentProps<typeof Drawer.Root>,
  | 'snapPoints'
  | 'activeSnapPoint'
  | 'setActiveSnapPoint'
  | 'modal'
  | 'dismissible'
>

/**
 * A draggable drawer component that pops out from the bottom. Although it supports desktop view, this pattern is intended for mobile use.
 * It is a wrapper around the Drawer component from Vaul => https://vaul.emilkowal.ski/getting-started
 * It is recommended to use the DraggableDrawerTitle and DraggableDrawerDescription components to provide an accessible title and description for the drawer.
 * Additionally, to enhance accessibility, a button to open the drawer and a close button should be provided.
 *
 * @param isOpen - Determines the visibility of the component
 * @param onCancel - Accepts a callback that's executed when the user clicks the close button
 * @param hasBackdrop - Determines if the backdrop (a slight shadow behind) is present when the drawer is open
 * @param isDraggable - Enables drag gestures to open and close the drawer, it also changes the visual aspect to make it look obvious for the user. Intended to use only in mobile.
 * @param snapPoints - Array of numbers from 0 to 1 that corresponds to % of the screen a given snap point should take up. You can also use px values, which doesn't take screen height into account.
 * @param activeSnapPoint - The controlled snap point state.
 * @param setActiveSnapPoint - Event handler called when the snap point state changes.
 * @param modal - When false it allows to interact with elements outside of the drawer without closing it.
 * @param dismissible - When false dragging, clicking outside, pressing esc, etc. will not close the drawer. Use this in combination with the open prop, otherwise you won't be able to open/close the drawer.
 * @param onClickIndicator - Event handler called when the indicator is clicked. It is recommended to provide this for accessibility purposes when the drawer is constantly open.
 * @param indicatorAriaLabel - The aria-label for the indicator button to provide context to the screen readers.
 * @param isContentScrollable - Determines if the content inside the drawer should be scrollable or not.
 */
export const DrawerDraggable = forwardRef<HTMLDivElement, DrawerDraggableProps>(
  (
    {
      children,
      hasBackdrop = false,
      isOpen,
      onCancel,
      snapPoints,
      activeSnapPoint,
      setActiveSnapPoint,
      modal,
      dismissible = true,
      onClickIndicator,
      indicatorAriaLabel,
      isContentScrollable = true,
    },
    ref
  ) => {
    return (
      <Drawer.Root
        open={isOpen}
        direction="bottom"
        onOpenChange={(open) => {
          if (!open) onCancel()
        }}
        snapPoints={snapPoints}
        activeSnapPoint={activeSnapPoint}
        setActiveSnapPoint={setActiveSnapPoint}
        modal={modal}
        dismissible={dismissible}
      >
        <Drawer.Portal>
          {hasBackdrop && <DraggableDrawerBackdrop />}
          <FocusScope trapped={dismissible}>
            <DraggableDrawerContent>
              <DraggableDrawerIndicator
                as={onClickIndicator ? 'button' : 'span'}
                onClick={onClickIndicator || undefined}
                aria-label={indicatorAriaLabel}
              />
              <DraggableDrawerChildren
                ref={ref}
                isContentScrollable={isContentScrollable}
              >
                {children}
              </DraggableDrawerChildren>
            </DraggableDrawerContent>
          </FocusScope>
        </Drawer.Portal>
      </Drawer.Root>
    )
  }
)

DrawerDraggable.displayName = 'DrawerDraggable'

// An accessible title to be announced when the drawer is opened.
export const DrawerDraggableTitle = Drawer.Title
// An optional accessible description to be announced when the drawer is opened.
export const DrawerDraggableDescription = Drawer.Description
