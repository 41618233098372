import styled from 'styled-components'

export const MapSRPContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.large};
`
)
type SRPContainerProps = {
  isMobileMapView?: boolean
}

export const MapContainer = styled.div<SRPContainerProps>(
  ({ isMobileMapView }) => `
  position: sticky;
  top: 7rem;
  height: ${isMobileMapView ? '70vh' : 'calc(100vh - 7rem)'};
  flex-basis: ${isMobileMapView ? '100%' : '65%'};
`
)

export const LiveLocationWrapper = styled.div(
  ({ theme }) => `
  background: ${theme.colors.purple.light2};
  padding: 8px;
  margin-right: 10px;
  border-radius: ${theme.borderRadius.small};
  margin-top: 5px;
  display: flex;
  cursor: pointer;
`
)
