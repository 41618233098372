import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

type SrpResultsTextProps = {
  $showOnLargeBreakpoint?: boolean
  $isSrpControlsMwebToggleEnabled?: boolean
}

export const SrpResultsText = styled(BodyText).attrs({ as: 'h2' })<SrpResultsTextProps>(
  ({ theme, $showOnLargeBreakpoint, $isSrpControlsMwebToggleEnabled }) => `
  ${$isSrpControlsMwebToggleEnabled ? 'align-self: center;' : ''}
  display: ${$showOnLargeBreakpoint && !$isSrpControlsMwebToggleEnabled ? 'none' : 'block'};
  
  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography({ ...theme.typography.body.medium })}
  }
  
  ${MEDIA_QUERIES(theme).large}{
    display: ${$showOnLargeBreakpoint ? 'block' : 'none'};
  }
`
)
