import React, { createContext, useContext, useMemo, useState } from 'react'

export enum GoogleMapEvents {
  INITIAL_LOAD = 'initial_load', // INITIAL_LOAD is a custom event to handle the first load of the map
  ZOOM_CHANGED = 'zoom_changed', // Native Google Map event
  JUST_CLICKED = 'just_clicked', // JUST_CLICKED is a custom event to handle the "Search this area" button
  TILES_LOADED = 'tilesloaded', // Native Google Map event
  IDLE = 'idle', // Native Google Map event
  DRAG = 'drag', // Native Google Map event
  CHANGED_USER_LOCATION = 'changed_user_location', // CHANGED_USER_LOCATION is a custom event to handle the flow where the user changes their location on the modal
  CLUSTER_CLICK = 'cluster_click', // CLUSTER_CLICK is a custom event to handle clicks on map marker clusters
}

type MapProps = {
  mapState: GoogleMapEvents
  renderSearchButton: boolean
  setMapState: (value: GoogleMapEvents) => void
  setRenderSearchButton: (value: boolean) => void
}

export const MapContext = createContext<MapProps>({} as MapProps)

export const MapContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [renderSearchButton, setRenderSearchButton] = useState(false)
  const [mapState, setMapState] = useState(GoogleMapEvents.INITIAL_LOAD)
  const mapProviderState = useMemo(
    () => ({
      mapState,
      setMapState,
      renderSearchButton,
      setRenderSearchButton,
    }),
    [mapState, renderSearchButton]
  )

  return <MapContext.Provider value={mapProviderState}>{children}</MapContext.Provider>
}

export function useMapContext() {
  const context = useContext(MapContext)

  if (context === undefined) {
    throw new Error('useMap must be used within a MapProvider')
  }
  return context
}
