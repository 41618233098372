import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const ROUNDED_DESKTOP_WIDTH = '18rem'
const DEFAULT_DESKTOP_WIDTH = '25.6rem'

export const SrpSortContainer = styled.div<{
  isRoundedSelect: boolean
  isSrpControlsMwebToggleEnabled: boolean
}>(
  ({ theme, isRoundedSelect, isSrpControlsMwebToggleEnabled }) => `
  height: auto;
  width: ${isSrpControlsMwebToggleEnabled ? 'auto' : '100%'};


  ${MEDIA_QUERIES(theme).medium}{
    width: ${isRoundedSelect ? ROUNDED_DESKTOP_WIDTH : DEFAULT_DESKTOP_WIDTH};
  }
`
)

export const SrpResultsSortContainer = styled(Flex)<{
  isSaveSearchActive: boolean
}>(
  ({ theme, isSaveSearchActive }) => `
  width: 100%;
  justify-content: ${!isSaveSearchActive ? 'space-between' : 'flex-end'};

  ${MEDIA_QUERIES(theme).large} {
    min-width: 42rem;
    align-items: center;
    justify-content: space-between;
  }
`
)

export const SaveSearchWrapper = styled(Flex)(
  ({ theme }) => `
  width: 50%;
  margin-right: 10px;
  margin-top: 1.6rem;

  ${MEDIA_QUERIES(theme).large} {
    display: none;
  }

  div:first-child  {
    padding:0;
    width: 100%;
  }
`
)
