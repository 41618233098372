import { StyledAdSenseSrpContainer } from '@/components-page/srp/advertisement/styled'
import { type AdSenseProps, AdSense } from '@/features/advertisement/components/adsense/AdSense'

export type AdSenseSrpWrapperProps = AdSenseProps & {
  children?: React.ReactNode
}

export const AdSenseSrpContainer = ({ id, children }: AdSenseSrpWrapperProps) => {
  return (
    <StyledAdSenseSrpContainer
      position={id === 'AFSTop' ? 'top' : 'bottom'}
      data-testid="adsense-container"
    >
      <AdSense id={id} />
      {children}
    </StyledAdSenseSrpContainer>
  )
}
