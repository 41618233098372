import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { getListingCounts } from '@/components/srp/results-sort/results/getListingCounts'
import { SrpResultsText } from '@/components/srp/results-sort/results/styled'
import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'
import { ROUTE_LOCALE } from '@/domain/locale'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useLocale } from '@/hooks/useLocale'
import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'

type SrpResultsProps = {
  /**
   * There are two placements for this results to be shown.
   * This flag is indicating if this component should be shown at large breakpoints or only smaller
   */
  showOnLargeBreakpoint?: boolean
  /**
   * We have a different translation to use if the user is browsing on mobile
   */
  isMobile?: boolean
}

export const SrpResults: FC<SrpResultsProps> = ({ showOnLargeBreakpoint, isMobile = false }) => {
  const { data } = useGetSearchResultsData()

  const { t } = useTranslation('srp')
  const { colors } = useTheme()
  const { routeLocale } = useLocale()

  const isEnglish = routeLocale === ROUTE_LOCALE.en

  const srpControlsMwebToggle = useToggle(RemoteParamKeys.SRP_CONTROLS_MWEB_REDESIGN)

  const {
    totalCount: totalListings = 0,
    offset = 0,
    limit = SRP_LISTING_LIMIT,
  } = data?.pagination || {}
  const currentPage = getCurrentPageFromPagination({ offset, limit })

  const listingCounts = getListingCounts(totalListings, currentPage, limit)
  if (srpControlsMwebToggle?.enabled && isMobile) {
    listingCounts.intl = 'header.results.total_count'
  }

  return (
    <SrpResultsText
      $showOnLargeBreakpoint={showOnLargeBreakpoint}
      $isSrpControlsMwebToggleEnabled={srpControlsMwebToggle?.enabled ?? false}
      aria-live="polite"
      color={colors.grey.primary}
      data-testid="srp-results"
      size="small"
    >
      {t(listingCounts.intl, {
        ...listingCounts.params,
        total: formatWholeNumber(totalListings, isEnglish),
      })}
    </SrpResultsText>
  )
}
