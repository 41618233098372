import { isAnyVacationRentalsCategory } from '@kijiji/category'
import { FilterPriceType } from '@kijiji/generated/graphql-types'

import { type ApiLocale } from '@/domain/locale'
import { type IntlResponse } from '@/types/translations'

type SrpStaticH1Props = {
  apiLocale: ApiLocale
  categoryId: number
  categoryName: string
  keyword: string | null
  locationName: string
  total: number
  priceType?: FilterPriceType
}

/**
 * Generates the static H1 title for the search results page based on various parameters such as category, keyword, location, and total results.
 * The override category file is only meant to be used for BRPs.
 *
 * The function handles different scenarios:
 * 1. Prefixes are added for cases with no results or free listings.
 * 2. Handles searches with or without keywords.
 * 3. Special handling for vacation rentals categories where subcategories are treated as locations.
 *
 * @param apiLocale - API request locale.
 * @param categoryId - ID of the search category.
 * @param categoryName - Category name for the search.
 * @param keyword - Keyword used for the search, if any.
 * @param locationName - Location name for the search.
 * @param total - Total number of listings found for the search.
 * @param priceType - Price filter type applied to the search, if any.
 *
 * @returns An object containing the internationalized string key and parameters for the H1 title.
 *
 * @example
 * ```typescript
 * const h1Title = getSrpStaticH1({
 *   apiLocale: 'en_CA',
 *   categoryId: 123,
 *   categoryName: 'Real Estate',
 *   keyword: 'apartment',
 *   locationName: 'Toronto',
 *   total: 0,
 *   priceType: FilterPriceType.GiveAway,
 * });
 * console.log(h1Title.intl); // "header.results.keyword_title"
 * console.log(h1Title.params); // { category: 'Real Estate', location: 'Toronto', keyword: 'apartment', prefix: 'No results for Free Listings for ' }
 * ```
 *
 * @todo After the translation package change, import the strings from: srp.meta for title and description.
 *       Since this is used by SSR function, we can't easily import the file translations here.
 */
export const getSrpStaticH1 = ({
  apiLocale,
  categoryId,
  categoryName,
  keyword,
  locationName,
  total,
  priceType,
}: SrpStaticH1Props): IntlResponse => {
  const params = {
    category: categoryName,
    location: locationName,
    keyword,
  }

  let prefix = ''
  if (!total) {
    if (!keyword || priceType === FilterPriceType.GiveAway) {
      prefix = apiLocale === 'en_CA' ? 'No results in ' : 'Aucun résultat dans '
    } else {
      prefix = apiLocale === 'en_CA' ? 'No results for ' : 'Aucun résultat pour '
    }
  }
  if (priceType === FilterPriceType.GiveAway) {
    if (!keyword) {
      prefix += apiLocale === 'en_CA' ? 'Free Listings in ' : 'Annonces gratuites dans '
    } else {
      prefix += apiLocale === 'en_CA' ? 'Free Listings for ' : 'Annonces gratuites pour '
    }
  }

  // Should include the vacation rentals key to the intl string if is L1 or any subcategory
  const vacationRentalsKey = isAnyVacationRentalsCategory(categoryId) ? `.vacation_rentals` : ''
  return {
    intl: keyword
      ? `header.results${vacationRentalsKey}.keyword_title`
      : `header.results${vacationRentalsKey}.title`,
    params: { ...params, prefix: prefix },
  }
}
