import { type FilterGroup } from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'

import {
  createAutosBodyLabel,
  createAutosModelLabel,
  createAutosYearLabel,
  createCentralMakeLabel,
  createCentralModelLabel,
  createCentralYearLabel,
  createForSaleByLabel,
  createPriceLabel,
} from '@/features/advertisement/components/gpt/attributesGpt'
import { createHostnameLabel } from '@/features/advertisement/components/gpt/configGpt'
import { createDeviceLabel } from '@/features/advertisement/components/gpt/deviceGpt'
import { createPageTargetLabel } from '@/features/advertisement/components/gpt/pageGpt'
import {
  addTrackingCategories,
  createCategoryIdLabel,
  createLocationIdLabel,
  createSearchKeywordTarget,
} from '@/features/advertisement/components/gpt/searchGpt'
import { createUserLoggedInLabelKj } from '@/features/advertisement/components/gpt/userGpt'
import { PAGE_TEST_GROUP_VALUES } from '@/features/advertisement/constants/gpt'
import { type GptTargeting } from '@/features/advertisement/types/adConfig'
import { getFilterSelectedValues } from '@/features/advertisement/utils/gptTargeting'
import { removeEmptyValues } from '@/features/advertisement/utils/removeEmptyValues'
import { getGenericTargetingKeys } from '@/features/advertisement/utils/targeting/getGenericTargetingKeys'
import { getIntowowTargetingKeys } from '@/features/advertisement/utils/targeting/getIntowowTargetingKeys'
import { getUserTargetingKeys } from '@/features/advertisement/utils/targeting/getUserTargetingKeys'
import useCookiePreferences from '@/hooks/useCookiePreferences'
import { type RouteLocale } from '@/hooks/useLocale'
import { RemoteParamKeys, useExperiment } from '@/lib/firebase/hooks'
import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import { getSearchPageType } from '@/lib/ga/utils/getSearchPageType'
import { debug } from '@/utils/debug'

export type UseSrpGptProps = {
  categories?: string[]
  categoryIds?: string[]
  currentPage: number
  filters: FilterGroup[]
  keywords: string
  locationId: number
  routeLocale: RouteLocale
  totalListings?: number
  isDesktopMapView: boolean
  isMobileMapView: boolean
}

// https://wiki.es.ecg.tools/pages/viewpage.action?pageId=160806983

export const useSrpGpt = ({
  categories = [],
  categoryIds,
  currentPage,
  filters,
  keywords,
  locationId,
  routeLocale,
  totalListings = 0,
  isDesktopMapView,
  isMobileMapView,
}: UseSrpGptProps): GptTargeting[] => {
  const { status: authenticationStatus } = useSession()
  const { hasUserOptedOutTargeting = false } = useCookiePreferences()

  const experiment = useExperiment(RemoteParamKeys.SMALLER_MASTHEAD_TEST)
  const { enabled, group } = experiment ?? {}
  const isMastheadReduced = enabled === true && group === 1
  const trackingKeyUpperCased = experiment?.trackingKey?.toLocaleUpperCase()
  const ptgMastheadReduced = isMastheadReduced
    ? `${trackingKeyUpperCased}_1`
    : `${trackingKeyUpperCased}_0`

  const ptgValues = [...PAGE_TEST_GROUP_VALUES, ptgMastheadReduced]

  let pageType =
    totalListings > 0 ? getSearchPageType(keywords) : GA_PAGE_TYPE.ZERO_SEARCH_RESULTS_PAGE

  if (isDesktopMapView || isMobileMapView) {
    if (pageType === GA_PAGE_TYPE.SEARCH_AD || pageType === GA_PAGE_TYPE.BROWSE_AD) {
      pageType = GA_PAGE_TYPE.MAP_SEARCH_RESULTS_PAGE
    }
  }

  const generic = getGenericTargetingKeys({
    ptgValues,
    pageType,
    hasUserOptedOutTargeting,
  })

  // values regarding the user's device
  const deprecatedDevice = [createDeviceLabel()]

  // values regarding the user
  const user = getUserTargetingKeys({ routeLocale, userStatus: authenticationStatus })
  const deprecatedUser = [createUserLoggedInLabelKj(authenticationStatus)]

  // values regarding the page
  const page = [createPageTargetLabel(currentPage)]

  // global values regarding the platform
  const deprecatedConfig = [createHostnameLabel()]

  // values regarding the search context
  const search = [
    createSearchKeywordTarget(keywords),
    createCategoryIdLabel(categoryIds),
    createLocationIdLabel(locationId),
    ...addTrackingCategories(categories),
  ]

  const yearFilters = getFilterSelectedValues(filters, 'yearSection')
  const forSaleByFilters = getFilterSelectedValues(filters, 'forSaleBySection')
  const makeFilters = getFilterSelectedValues(filters, 'makeSection')
  const bodyFilters = getFilterSelectedValues(filters, 'bodyTypeSection')
  const priceFilters = getFilterSelectedValues(filters, 'priceSection')
  const modelFilters = getFilterSelectedValues(filters, 'modelSection')

  // values regarding the attributes selected by the filters
  const attributes = [createPriceLabel(priceFilters), createForSaleByLabel(forSaleByFilters)]

  // values regarding the autos attributes selected by the filters
  const attributesAutos = [
    createAutosYearLabel(yearFilters),
    createCentralMakeLabel(makeFilters),
    createAutosBodyLabel(bodyFilters),
    createCentralYearLabel(yearFilters),
    createCentralModelLabel(modelFilters),
    createAutosModelLabel(modelFilters),
  ]

  const intowowTargeting = getIntowowTargetingKeys()

  const gptKeyValues = removeEmptyValues([
    ...generic,

    // Page
    ...page,

    // Config
    ...deprecatedConfig,

    // Device info
    ...deprecatedDevice,

    // User
    ...user,
    ...deprecatedUser,

    // Search context
    ...search,

    // Attributes
    ...attributes,

    // Autos attributes
    ...attributesAutos,

    // Intowow test groups
    ...intowowTargeting,
  ])

  const generateGptTable = () => {
    // eslint-disable-next-line no-console
    console.table(gptKeyValues)
  }

  debug(generateGptTable)

  return gptKeyValues
}
