import {
  type BoundingBox,
  type ListingFragment,
  type UserLocation,
} from '@kijiji/generated/graphql-types'
import LocationServiceIcon from '@kijiji/icons/src/icons/LocationServiceOn'
import {
  type MapCameraChangedEvent,
  type MapEvent,
  APIProvider as GoogleAPIProvider,
  ControlPosition,
  Map as GoogleMap,
  MapControl,
  useMap,
} from '@vis.gl/react-google-maps'
import { useRouter } from 'next/router'
import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { useLiveLocation } from '@/components/shared/search-location-modal/useLiveLocation'
import { DEFAULT_CLOSE_TO_ME_RADIUS, MINIMUM_RADIUS_VALUE } from '@/constants/location'
import { type LanguageKey } from '@/domain/locale'
import { defaultLocation, MAP_ID } from '@/features/map/components/constants'
import { MapSearchButton } from '@/features/map/components/search-button/MapSearchButton'
import { LiveLocationWrapper } from '@/features/map/components/styled'
import { MIN_ZOOM_LEVEL } from '@/features/map/constants/map'
import { GoogleMapEvents, useMapContext } from '@/features/map/hooks/useMapContext'
import { useMapSRPState } from '@/features/map/hooks/useMapSRPState'
import { getGoogleMapsKey } from '@/features/map/utils/getGoogleMapsKey'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useSearchActions } from '@/hooks/srp'
import { useFetchLocationFromCoordinates } from '@/hooks/useFetchLocationFromCoordinates'
import { useLocale } from '@/hooks/useLocale'
import { sendToLogger } from '@/utils/sendToLogger'

/**
 * Props for the MapProvider component.
 */
type MapProviderProps = {
  children: ReactNode
  provider?: string
}

/**
 * Props for the Map component.
 *
 * @see https://visgl.github.io/react-google-maps/docs - Documentation for **vis.gl** (if integrating or using vis.gl for other mapping libraries).
 * @see https://developers.google.com/maps/documentation/javascript/reference/map - Documentation for **Google Maps** API.
 */
type MapProps = {
  provider?: string
  listings?: ListingFragment[]
  currentPage?: number
  isMobileMapView?: boolean
} & React.ComponentProps<typeof GoogleMap>

/**
 * MapProvider component that manages the integration with different map providers.
 * Currently supports the 'google' provider, fetching the API key dynamically based on the language key.
 *
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The child components that will be rendered within the MapProvider.
 * @param {string} [props.provider='google'] - The map provider to use. Defaults to 'google'.
 *
 * @returns {React.ReactNode | null} - The component returns either the GoogleAPIProvider with the children or null if an unsupported provider is specified.
 *
 * @note This component fetches the Google Maps API key based on the current language and provider settings.
 */
const MapProvider: React.FC<MapProviderProps> = ({ children, provider = 'google' }) => {
  const { languageKey } = useLocale()
  const [googleApiKey, setGoogleApiKey] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const getGoogleMapsAPIKeyByInstance = async (languageKey: LanguageKey) => {
    try {
      const { apiKey } = await getGoogleMapsKey(languageKey)
      if (!apiKey) throw new Error('Google Maps API key is missing')
      setGoogleApiKey(apiKey)
    } catch (err: unknown) {
      sendToLogger(err, { fingerprint: ['MapSRP', 'GMKFetching'] })
      setError('Failed to load Google Maps API. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchGoogleApiKey = async () => {
      setLoading(true)
      setError(null)
      await getGoogleMapsAPIKeyByInstance(languageKey)
    }
    if (provider === 'google') {
      fetchGoogleApiKey()
    } else {
      setLoading(false)
    }
  }, [languageKey, provider])

  if (loading) {
    return <div>Loading map...</div>
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', color: 'red' }}>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    )
  }

  if (provider === 'google')
    return <GoogleAPIProvider apiKey={googleApiKey}>{children}</GoogleAPIProvider>

  return null
}

export const calculateDistance = ({
  startLat,
  startLong,
  endLat,
  endLong,
}: {
  startLat: number
  startLong: number
  endLat: number
  endLong: number
}): number => {
  const R = 6371000 // Radius of the Earth in meters
  const rlat1 = startLat * (Math.PI / 180) // Convert degrees to radians
  const rlat2 = endLat * (Math.PI / 180) // Convert degrees to radians
  const difflat = rlat2 - rlat1 // Radian difference (latitudes)
  const difflon = (endLong - startLong) * (Math.PI / 180) // Radian difference (longitudes)

  const a =
    Math.sin(difflat / 2) * Math.sin(difflat / 2) +
    Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)

  const c = 2 * Math.asin(Math.sqrt(a))

  return R * c // Distance in meters
}
export const calculateBoundingBoxRadius = (boundingBox: BoundingBox): number => {
  const { ne, sw } = boundingBox

  // Calculate the center of the bounding box
  const centerLat = (ne.latitude + sw.latitude) / 2
  const centerLong = (ne.longitude + sw.longitude) / 2

  // Calculate the distance from the center to the northeast corner
  return calculateDistance({
    startLat: centerLat,
    startLong: centerLong,
    endLat: ne.latitude,
    endLong: ne.longitude,
  })
}

/**
 * MapListing component that renders a map from the specified provider.
 * Currently supports the 'google' provider and renders the GoogleMap component.
 *
 * @param {Object} props - The component's props.
 * @param {string} [props.provider='google'] - The map provider to use. Defaults to 'google'.
 * @param {Object} [props.rest] - Any other additional props passed to the GoogleMap component.
 *
 * @returns {React.ReactNode | null} - The component returns the GoogleMap component with the provided props or null if an unsupported provider is specified.
 *
 * @note This component is currently limited to rendering Google Maps based on the provider prop.
 */
const MapListing: React.FC<MapProps> = ({
  provider = 'google',
  isMobileMapView,
  listings,
  currentPage = 1,
  children,
  ...rest
}) => {
  const { location: userLocation, updateUserLocation } = useGetLocation()
  const { renderSearchButton, setRenderSearchButton, mapState, setMapState } = useMapContext()
  const map = useMap()
  const { fetchLocationFromCoordinates } = useFetchLocationFromCoordinates()
  const { refetchResults } = useSearchActions()
  const router = useRouter()
  const { shouldUpdateLocationRef, setMapSRPState } = useMapSRPState()
  const { getLiveLocation } = useLiveLocation()

  //user location is a ref because we need to compare the previous location with the new one without re-rendering the component
  const currentUserLocation = useRef<UserLocation>()
  if (!currentUserLocation.current) {
    currentUserLocation.current = userLocation
  }

  // This callback is triggered when the map is manipulated (pan, zoom, tiles loads - AKA map fully loaded)
  // The Search button is not visible on first load, hence the first escape clause
  const handleMapInteraction = useCallback(
    async (event: MapEvent<unknown> | MapCameraChangedEvent) => {
      const mapBounds = map?.getBounds()?.toJSON()

      if (!mapBounds) return

      const { north, south, east, west } = mapBounds

      const mapRadius = Math.round(
        calculateBoundingBoxRadius({
          ne: { latitude: north, longitude: east },
          sw: { latitude: south, longitude: west },
        })
      )

      const radius = mapRadius / 1000

      if (radius < MINIMUM_RADIUS_VALUE) {
        setRenderSearchButton(false)
      }

      if (event.type === GoogleMapEvents.TILES_LOADED) {
        if (mapState === GoogleMapEvents.INITIAL_LOAD) {
          setRenderSearchButton(false)
          setMapState(GoogleMapEvents.TILES_LOADED)
        }

        return
      }
      // If the map is loading, the first event is the zoom_changed event, we don't want to trigger the search button
      if (
        mapState === GoogleMapEvents.INITIAL_LOAD &&
        event.type === GoogleMapEvents.ZOOM_CHANGED
      ) {
        return
      }
      // if user is zooming or panning with the map with the map, we show the search button
      // Map and results don't change until search button is clicked or user location is changed
      if (
        event.type === GoogleMapEvents.DRAG ||
        (event.type === GoogleMapEvents.ZOOM_CHANGED &&
          mapState !== GoogleMapEvents.CHANGED_USER_LOCATION)
      ) {
        const shouldRenderSearchButton =
          radius >= MINIMUM_RADIUS_VALUE && mapState !== GoogleMapEvents.CLUSTER_CLICK

        setRenderSearchButton(shouldRenderSearchButton)
        setMapState(event.type as GoogleMapEvents)

        return
      }

      setRenderSearchButton(false)
    },
    [map, mapState, setMapState, setRenderSearchButton]
  )

  //This useEffect is to update the user location ref when the user changes the location on the location modal
  //also triggers the state CHANGED_USER_LOCATION to update the map bounds
  useEffect(() => {
    if (!shouldUpdateLocationRef) return

    const hasCoordsChanged =
      currentUserLocation.current?.area?.latitude !== userLocation.area?.latitude ||
      currentUserLocation.current?.area?.longitude !== userLocation.area?.longitude
    const hasRadiusChanged = currentUserLocation.current?.area?.radius !== userLocation.area?.radius
    const hasLocationIdChanged = currentUserLocation.current?.id !== userLocation.id

    const hasUserLocationChanged = hasCoordsChanged || hasRadiusChanged || hasLocationIdChanged

    if (hasUserLocationChanged) {
      setMapState(GoogleMapEvents.CHANGED_USER_LOCATION)
      currentUserLocation.current = userLocation
    }
  }, [
    mapState,
    setMapSRPState,
    setMapState,
    shouldUpdateLocationRef,
    updateUserLocation,
    userLocation,
    userLocation.area?.radius,
    userLocation.id,
  ])

  /**
   * See previous POC branch (`https://github.mpi-internal.com/ecg-kijiji-ca/kijiji-frontend/pull/2963`).
   * As per this page https://confluence.ets.mpi-internal.com/display/FE/Bringing+the+Map+SRP+from+the+APP+to+WEB
   */
  useEffect(() => {
    const { bb, radius } = router.query

    //if the user is just panning or zooming the map, we do nothing
    if (
      !map ||
      mapState === GoogleMapEvents.DRAG ||
      mapState === GoogleMapEvents.IDLE ||
      mapState === GoogleMapEvents.TILES_LOADED ||
      mapState === GoogleMapEvents.ZOOM_CHANGED
    ) {
      return
    }

    //We check for JUST_CLICKED because this action is covered in the next if clause
    // Whenever the user changes it's location, we need to fit the map to the new location
    // or if the user switches from list view, we wan't to fit the bounds of the map to the user's selected radius
    if (
      mapState !== GoogleMapEvents.JUST_CLICKED &&
      (mapState === GoogleMapEvents.CHANGED_USER_LOCATION || (radius && google.maps.Circle))
    ) {
      if (!userLocation.area?.radius) return

      // We create a google.maps.Circle,
      const circ = new google.maps.Circle()

      // We make it the radius of the user selected radius, and set the center to the user selected location,
      circ.setRadius(userLocation.area.radius * 1000)
      circ.setCenter({
        lat: userLocation.area.latitude,
        lng: userLocation.area.longitude,
      })

      // We get the bounds of the circle,
      const bounds = circ.getBounds()

      // Then, fit the map according to the circle's bounds
      if (bounds) {
        map?.fitBounds(bounds, 0)
        const { north, east, south, west } = bounds.toJSON()
        refetchResults({
          location: {
            id: userLocation.id,
            boundingBox: {
              ne: { latitude: north, longitude: east },
              sw: { latitude: south, longitude: west },
            },
            area: userLocation.area,
          },
        })
      }
      //...then set the map state to idle
      setMapState(GoogleMapEvents.IDLE)
    } else if (bb && mapState === GoogleMapEvents.INITIAL_LOAD) {
      // If the url has a bounding box query parameter, we need to parse for those coordinates and fit the map to them.
      // This scenario is for reloads only.
      setRenderSearchButton(false)
      const [ne, sw] = (bb as string).split('+')
      const [lat1, lng1] = ne.split(',')
      const [lat2, lng2] = sw.split(',')
      const parsedNorth = Number(lat1)
      const parsedEast = Number(lng1)
      const parsedSouth = Number(lat2)
      const parsedWest = Number(lng2)

      map?.fitBounds(
        {
          north: parsedNorth,
          east: parsedEast,
          south: parsedSouth,
          west: parsedWest,
        },
        0
      )
      setMapState(GoogleMapEvents.IDLE)
      if (currentPage > 1) return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    map,
    mapState,
    router.query.bb,
    router.query,
    setMapState,
    setRenderSearchButton,
    currentPage,
    userLocation,
  ])

  // When the map is manipulated (pan, zoom) the "Search this area" button appears
  // When clicked it needs to re-search, sync the new location/radius to the site header, and hide itself
  const handleSearchButtonClick = useCallback(async () => {
    setRenderSearchButton(false)
    setMapState(GoogleMapEvents.JUST_CLICKED)
    setMapSRPState({ shouldUpdateLocationRef: false })

    const mapCenter = map?.getCenter()?.toJSON()
    const mapBounds = map?.getBounds()?.toJSON()

    if (!mapCenter || !mapBounds) return

    const { north, south, east, west } = mapBounds

    const radius = calculateBoundingBoxRadius({
      ne: { latitude: north, longitude: east },
      sw: { latitude: south, longitude: west },
    })

    const mapRadius = Math.round(radius / 1000)

    const newUserLocation = await fetchLocationFromCoordinates({
      latitude: mapCenter.lat,
      longitude: mapCenter.lng,
    })

    if (!newUserLocation?.area) return

    updateUserLocation({
      id: newUserLocation.id,
      isRegion: false,
      name: newUserLocation.name,
      area: {
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        radius: mapRadius,
        address: newUserLocation?.area?.address ?? '',
      },
    })

    refetchResults({
      location: {
        id: newUserLocation.id,
        boundingBox: {
          ne: { latitude: north, longitude: east },
          sw: { latitude: south, longitude: west },
        },
        area: newUserLocation.area,
      },
    })
  }, [
    setRenderSearchButton,
    setMapState,
    setMapSRPState,
    map,
    fetchLocationFromCoordinates,
    updateUserLocation,
    refetchResults,
  ])

  const handleLiveLocationSelection = useCallback(async () => {
    const liveLocation = await getLiveLocation()
    if (
      !liveLocation?.area?.latitude ||
      !liveLocation?.area?.longitude ||
      !liveLocation?.area?.address ||
      !userLocation.area?.radius
    )
      return
    updateUserLocation({
      id: liveLocation.id,
      isRegion: false,
      name: liveLocation.name,
      area: {
        latitude: liveLocation.area.latitude,
        longitude: liveLocation.area.longitude,
        radius: DEFAULT_CLOSE_TO_ME_RADIUS,
        address: liveLocation.area.address,
      },
    })
    setMapSRPState({ shouldUpdateLocationRef: true })
  }, [getLiveLocation, setMapSRPState, updateUserLocation, userLocation.area?.radius])

  const renderMapProvider = useCallback(() => {
    if (provider === 'google') {
      return (
        <GoogleMap
          // The map requires a unique MAP_ID for features like Advanced Markers.
          mapId={MAP_ID}
          disableDefaultUI={true}
          zoomControl={true}
          defaultCenter={defaultLocation}
          defaultZoom={defaultLocation.defaultZoom}
          onDrag={handleMapInteraction}
          onZoomChanged={handleMapInteraction}
          zoomControlOptions={{ position: ControlPosition.RIGHT_TOP }}
          onTilesLoaded={handleMapInteraction}
          minZoom={MIN_ZOOM_LEVEL}
          {...rest}
        >
          <MapControl position={ControlPosition.TOP_RIGHT}>
            <LiveLocationWrapper>
              <LocationServiceIcon
                size="default"
                color="#FFF"
                onClick={handleLiveLocationSelection}
              />
            </LiveLocationWrapper>
          </MapControl>
          {children}
        </GoogleMap>
      )
    } else {
      sendToLogger(new Error('Unsupported map provider'), {
        fingerprint: ['MapSRP', 'UnsupportedMapProvider'],
      })
      return null
    }
  }, [children, handleLiveLocationSelection, handleMapInteraction, provider, rest])

  return (
    <>
      {renderMapProvider()}
      {renderSearchButton && (
        <MapSearchButton data-testid="search-button" onClick={handleSearchButtonClick} />
      )}
    </>
  )
}

export { MapListing, MapProvider }
