import {
  type FilterGroup,
  type FilterPriceType,
  type Locale,
  type SearchCategory,
} from '@kijiji/generated/graphql-types'
import { type TFunction } from 'next-i18next'
import { type NextSeoProps } from 'next-seo'

import { type ApiLocale } from '@/domain/locale'
import { getMetaTitleDescriptionH1 } from '@/domain/srp/seo/getMetaTitleDescriptionH1'
import { getSrpCanonicalUrl } from '@/domain/srp/seo/getSrpCanonicalUrl'
import { getSrpStaticH1 } from '@/domain/srp/seo/getSrpStaticH1'
import { type Listing } from '@/types/search'
import { type IntlResponse } from '@/types/translations'
import { createRobotsContent } from '@/utils/seo'
import { resolveCategoryName } from '@/utils/srp/resolveCategoryName'

export type RobotsProps = {
  noindex: boolean
  nofollow: boolean
}

type SrpMetadataResponse = NextSeoProps & {
  h1: string | IntlResponse
}

export type SrpMetaDataProps = {
  t: TFunction
  apiLocale: ApiLocale
  category: SearchCategory
  currentPage: number
  filters: FilterGroup[]
  firstListing?: Listing
  keyword: string | null
  location?: Locale | null
  totalListingsCount: number
  url: URL
  canonicalUrl?: string
  priceType?: FilterPriceType
}

export const getSrpMetaData = ({
  t,
  apiLocale,
  category,
  currentPage,
  filters,
  firstListing,
  keyword,
  location,
  totalListingsCount,
  url,
  canonicalUrl,
  priceType,
}: SrpMetaDataProps): SrpMetadataResponse => {
  const categoryName = resolveCategoryName({
    t,
    category,
    apiLocale,
    priceType,
  })

  /** Page title & description comes from an excel sheet */
  const { description, title, dynamicH1 } = getMetaTitleDescriptionH1({
    apiLocale,
    category,
    categoryName,
    filters,
    keyword,
    location,
    totalListingsCount,
    priceType,
  })

  /** It should only get static H1 if no dynamic was found */
  const pageH1 =
    dynamicH1 ||
    getSrpStaticH1({
      apiLocale: apiLocale,
      categoryId: category.id,
      categoryName,
      keyword,
      locationName: `${location?.[apiLocale]}`,
      total: totalListingsCount,
      priceType: priceType,
    })

  /**
   * The SEO will get some information from the first listings of the SRP:
   * - title & Image
   */
  const seoImages = firstListing?.imageUrls[0]
    ? [{ url: firstListing.imageUrls[0], alt: firstListing.title }]
    : []

  const ogMetaData = { openGraph: { url: url.href, images: seoImages } }

  const srpCanonicalUrl = canonicalUrl || getSrpCanonicalUrl(url, category)

  /** Define meta robots based on total count */
  const robotsContent: RobotsProps = createRobotsContent(totalListingsCount)

  /** Display page number on title */
  const displayPageNumber = currentPage > 1 ? ` - Page ${currentPage}` : ''

  const seo: SrpMetadataResponse = {
    description,
    title: `${title}${displayPageNumber}`,
    h1: pageH1,
    canonical: srpCanonicalUrl,
    ...robotsContent,
    ...ogMetaData,
  }

  return seo
}
