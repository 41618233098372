import { AdditionalFlags } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { RenderListingCard } from '@/components/srp/search-list/RenderListingCard'
import {
  type SearchListContainerProps,
  SearchListContainer,
  TopAdsContainer,
} from '@/components/srp/search-list/styled'
import { useSearchActions } from '@/hooks/srp'
import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type Listing } from '@/types/search'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { TextLink } from '@/ui/atoms/text-link'

type RenderTopAdsProps = {
  globalCategoryId: number
  listingsGap: SearchListContainerProps['gap']
  isMobile?: boolean
  topAds: Listing[]
  shouldRenderMapSRP?: boolean
}

export const RenderTopAds = ({
  listingsGap,
  isMobile,
  topAds,
  shouldRenderMapSRP,
}: RenderTopAdsProps) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation(['srp', 'common'])

  const { refetchResults } = useSearchActions()

  const srpControlsMwebToggle = useToggle(RemoteParamKeys.SRP_CONTROLS_MWEB_REDESIGN)
  const isSrpControlsMwebToggleEnabled = !!(srpControlsMwebToggle?.enabled && isMobile)

  const handleSeeAllTopAds = () =>
    refetchResults({
      additionalFlagFilters: [AdditionalFlags.TopAd],
      offset: 0,
      topAdCount: 0,
    })

  if (!topAds.length) return null

  const trackTopAdLearnMoreClick = () => {
    trackEvent({ action: GA_EVENT.TopAdLearnMoreClick })
  }

  const renderSeeAllTextLink = () => {
    return (
      <TextLink
        as="button"
        hasUnderline
        onClick={handleSeeAllTopAds}
        variant="secondary"
        data-testid="see-all-top-ads"
      >
        {t('common:labels.see_all')}
      </TextLink>
    )
  }

  return (
    <TopAdsContainer isSrpControlsMwebToggleEnabled={isSrpControlsMwebToggleEnabled}>
      <Spacing mBottom={spacing.default}>
        {isSrpControlsMwebToggleEnabled ? (
          <Flex justifyContent="space-between">
            <BodyText size="xLarge" weight="semiBold" color={colors.grey.primary}>
              {t('srp:listing.top_ad.mobile.label')}
            </BodyText>
            {renderSeeAllTextLink()}
          </Flex>
        ) : (
          <BodyText size="medium" color={colors.grey.primary}>
            {t('srp:listing.top_ad.label')}{' '}
            <LinkCustom
              aria-label={t('srp:listing.top_ad.learn_more_long_label')}
              href={t('srp:listing.top_ad.learn_more_link')}
              hasUnderline
              onClick={trackTopAdLearnMoreClick}
              variant="secondary"
            >
              {t('common:labels.learn_more')}
            </LinkCustom>
          </BodyText>
        )}
      </Spacing>

      <SearchListContainer
        data-testid="top-listings"
        gap={listingsGap}
        isSrpControlsMwebToggleEnabled={isSrpControlsMwebToggleEnabled}
      >
        {topAds.map((item, index) => (
          <RenderListingCard
            key={item.id}
            index={index}
            isMobile={isMobile}
            isTopAd
            item={item}
            isMapCard={shouldRenderMapSRP}
          />
        ))}
      </SearchListContainer>

      {!shouldRenderMapSRP && !isSrpControlsMwebToggleEnabled && (
        <Spacing mTop={spacing.default}>{renderSeeAllTextLink()}</Spacing>
      )}
    </TopAdsContainer>
  )
}
