import { isAnyRealEstateCategory } from '@kijiji/category'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { AdvertisingSlot } from 'react-advertising'
import { useTheme } from 'styled-components'

import {
  FiltersAccordion,
  FiltersAccordionOrigin,
} from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import {
  FiltersSidebarPanel,
  FiltersSidebarTitle,
  SkyscraperAdSlotContainer,
} from '@/components/srp/filters/FiltersSidebar/styled'
import { FiltersDivider } from '@/components/srp/filters/styled'
import { SrpLoading } from '@/components/srp/srp-loading'
import { GPT_COMPONENT_ID } from '@/features/advertisement/constants/adSlots'
import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'
import { GA_SAVE_SEARCH_TYPES } from '@/lib/ga/constants/ga'
import { getUserDevice } from '@/utils/getUserDevice'

const SaveSearchChipButton = dynamic(
  () => import('@/components/srp/save-search-chip').then((mod) => mod.SaveSearchChipButton),
  { ssr: false }
)

export type FiltersSidebarProps = {
  isMobileForAds?: boolean
  isLoadingFilters?: boolean
  categoryId?: number
  shouldRenderMapSRP?: boolean
}

export const FiltersSidebar = ({
  isMobileForAds,
  isLoadingFilters,
  categoryId = 0,
  shouldRenderMapSRP = false,
}: FiltersSidebarProps) => {
  const { t } = useTranslation('srp')
  const { colors } = useTheme()
  const saveSearchWithinFiltersDecision = useToggle(RemoteParamKeys.SAVED_SEARCH_WITHIN_FILTERS)
  const srpControlsMwebToggle = useToggle(RemoteParamKeys.SRP_CONTROLS_MWEB_REDESIGN)
  const isMobileDevice = getUserDevice().isPhone
  const isSrpControlsMwebToggleEnabled = !!srpControlsMwebToggle?.enabled && isMobileDevice

  const areQuickFilterPillsActive =
    isAnyRealEstateCategory(categoryId) || shouldRenderMapSRP || isSrpControlsMwebToggleEnabled

  return (
    <div>
      <FiltersSidebarPanel data-testid="srp-desktop-filter">
        {saveSearchWithinFiltersDecision?.enabled && (
          <SaveSearchChipButton eventTrackingSource={GA_SAVE_SEARCH_TYPES.btn} />
        )}
        {!areQuickFilterPillsActive ? (
          <>
            <FiltersSidebarTitle color={colors.grey.primary} as="h2">
              {t('filters.title')}
            </FiltersSidebarTitle>
            <FiltersDivider />
          </>
        ) : null}

        {isLoadingFilters ? (
          <SrpLoading
            variant={areQuickFilterPillsActive ? 'sidebar-filter-simplified' : 'sidebar-filter'}
          />
        ) : (
          <FiltersAccordion
            isSimplified={areQuickFilterPillsActive}
            origin={FiltersAccordionOrigin.SIDE_PANEL}
          />
        )}
      </FiltersSidebarPanel>

      {!isMobileForAds && (
        <SkyscraperAdSlotContainer areQuickFilterPillsActive={areQuickFilterPillsActive}>
          <AdvertisingSlot
            id={GPT_COMPONENT_ID.SKYSCRAPER}
            data-testid={GPT_COMPONENT_ID.SKYSCRAPER}
          />
        </SkyscraperAdSlotContainer>
      )}
    </div>
  )
}
