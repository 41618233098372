import { SearchIcon } from '@kijiji/icons/src/icons'
import { colors, theme } from '@kijiji/theme'
import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'

export const SearchAreaButtonStyles = styled(Button)`
  height: ${theme.spacing.husky};
  position: absolute;
  top: ${theme.spacing.husky};
  left: 50%;
  transform: translate(-50%, -50%);
  border-color: ${colors.purple.primary};
  box-shadow: ${theme.boxShadow['shadow-3']};
  &:hover:active {
    background-color: ${colors.purple.light5};
    border-color: ${colors.purple.primary};
  }
`

export const SearchAreaButtonText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  flex-grow: 1;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.spacing.default};
`
export const SearchAreaFlexContainer = styled(Flex)`
  max-width: 100%;
`
export const SearchAreaIcon = styled(SearchIcon)`
  flex-shrink: 0;
`
