import {
  type LocationCoordinates,
  useGetOrganicListingsQuery,
} from '@kijiji/generated/graphql-types'
import omit from 'lodash/omit'
import { useMemo } from 'react'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { ALL_CANADA_LOCATION_ID, RADIUS_SLIDER_HALF_POINT_KMS } from '@/constants/location'
import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'
import { LOW_INVENTORY_LIMIT } from '@/constants/search'
import { filterExtendedSearchFromListings } from '@/domain/extendedSearch'
import { getExtendedRadius } from '@/domain/lowInventory'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { useGetSearchResultsData } from '@/hooks/srp'
import { trackEvent } from '@/lib/ga'
import { getSearchPageType } from '@/lib/ga/utils/getSearchPageType'
import { type Listing, stripTypename } from '@/types/search'
import { removeTypenameFromObject } from '@/utils/removeTypenameFromObject'
import { sendToLogger } from '@/utils/sendToLogger'

export type UseExtendedSearchResponse = { extendedListings: Listing[] }

export const useExtendedSearch = (): UseExtendedSearchResponse => {
  const { data: currentData } = useGetSearchResultsData()

  const { searchQuery, pagination, controls, results } = currentData || {}

  const currentSort = controls?.sorting.find((sort) => sort.isSelected)
  const categoryId = searchQuery?.category?.id || ALL_CATEGORIES_ID_NUM

  const locationId = searchQuery?.location?.id || ALL_CANADA_LOCATION_ID

  /**
   * The area coming from the SearchQuery with __typename which we can't sent back to the query
   * we are removing _typename to avoid issues
   */
  const locationArea = removeTypenameFromObject(searchQuery?.area)

  /**
   * The extended search refetch should be done with the same parameters as the last search
   * Adding 50% increase in the radius parameter
   *  */
  const extendedRadius = useMemo(
    () => getExtendedRadius(locationArea?.radius || RADIUS_SLIDER_HALF_POINT_KMS),
    [locationArea?.radius]
  )

  const cleanBoundingBox = locationArea?.boundingBox && {
    ne: stripTypename<LocationCoordinates>(locationArea.boundingBox.ne),
    sw: stripTypename<LocationCoordinates>(locationArea.boundingBox.sw),
  }

  const areaWithoutBB = locationArea ? omit(locationArea, ['boundingBox']) : null

  const skipFetchExtendedListings =
    !searchQuery ||
    !pagination ||
    !searchQuery.area ||
    getCurrentPageFromPagination(pagination) > 1 ||
    pagination.totalCount > LOW_INVENTORY_LIMIT

  const paginationInput = {
    limit: pagination?.limit ?? SRP_LISTING_LIMIT,
    offset: pagination?.offset ?? 0,
  }

  const { data } = useGetOrganicListingsQuery({
    skip: skipFetchExtendedListings,
    variables: {
      input: {
        pagination: paginationInput,
        ...((currentSort && {
          sorting: { by: currentSort.by, direction: currentSort.direction },
        }) ||
          {}),
        searchQuery: {
          attributeFilters: [],
          categoryId,
          dateRangeFilters: [],
          keywords: searchQuery?.keywords || '',
          location: {
            id: locationId,
            area: areaWithoutBB
              ? { ...areaWithoutBB, radius: areaWithoutBB.radius + extendedRadius }
              : undefined,
            boundingBox: cleanBoundingBox || undefined,
          },
          rangeFilters: [],
        },
      },
      pagination: paginationInput,
    },
    onCompleted: () => {
      trackEvent({
        action: getSearchPageType(searchQuery?.keywords),
        label: `OutsideLocationAdsLoaded;distance_from_outside_location=${extendedRadius}`,
      })
    },
    onError: (error) => sendToLogger(error, { fingerprint: ['useExtendedSearch'] }),
  })

  const listings = data?.searchResultsPageUpdate.results?.mainListings || []
  const extendedListings = filterExtendedSearchFromListings(results?.mainListings || [], listings)

  return { extendedListings }
}
