import { colors, theme } from '@kijiji/theme'
import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'

export const MapViewButtonStyles = styled(Button)`
  height: ${theme.spacing.husky};
  color: ${colors.grey.primary};
  &:hover,
  &:active {
    background-color: ${colors.purple.light5};
    border-color: ${colors.grey.light3};
    color: ${colors.grey.primary};
  }
`

export const MapViewButtonText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  flex-grow: 1;
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.spacing.default};
`
export const MapViewFlexContainer = styled(Flex)`
  max-width: 100%;
`
