import { type TFunction } from 'next-i18next'

import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { getSingleListingStructuredMarkup } from '@/features/seo/utils/structured-markups/getSingleListingStructuredMarkup'
import { type Listing } from '@/types/search'

/**
 * @param listings topAds + organic listings
 * @param t i18n translation function
 *
 * @returns structured markup for a list of multiple listings from search
 */
export const getListingsStructuredMarkup = (listings: Listing[], t: TFunction) => {
  const filteredListings = listings.filter((listing) => isAmountPrice(listing.price))

  const itemListElement = filteredListings.map((listing, index) => {
    return getSingleListingStructuredMarkup({
      listing,
      t,
      position: index + 1,
      includeMarkup: { mainImageOnly: true },
    })
  })

  return itemListElement
}
