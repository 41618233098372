import { FilterPriceType } from '@kijiji/generated/graphql-types'

import { type ApiLocale, API_LOCALE } from '@/domain/locale'

type SrpStaticMetadata = {
  title: string
  description: string
}

type SrpMetaStaticFallbackProps = {
  apiLocale: ApiLocale
  keyword?: string | null
  locationName: string
  categoryName: string
  priceType?: FilterPriceType
}

/**
 * Responsible for returning the static translation for title and description of the search page.
 * Cases where the static metadata should be used:
 * - Keyword search: should always use the static title/description.
 * - Not keyword search: if reading the excel file fails, then use the static title/description.
 *
 * @param apiLocale - API request locale.
 * @param keyword - Keyword used for the search, if any.
 * @param locationName - Location name for the search.
 * @param categoryName - Category name for the search.
 * @param priceType - Price filter type applied to the search, if any.
 *
 * @returns The static metadata containing the title and description for the search page.
 *
 * @example
 * ```typescript
 * const metadata = getSrpMetaStaticFallback({
 *   apiLocale: API_LOCALE.en_CA,
 *   keyword: 'bicycle',
 *   locationName: 'Toronto',
 *   categoryName: 'Sporting Goods',
 *   priceType: FilterPriceType.ForSale,
 * });
 * console.log(metadata.title); // "bicycle in Sporting Goods in Toronto | Kijiji Marketplaces"
 * console.log(metadata.description); // "Find bicycle in Sporting Goods in Toronto. Visit Kijiji Classifieds to buy, sell, or trade almost anything! Find new and used items, cars, real estate, jobs, services, vacation rentals and more virtually in Toronto."
 * ```
 *
 * @todo After the translation package change, import the strings from: srp.meta for title and description.
 *       Since this is used by SSR function, we can't easily import the file translations here.
 */
export const getSrpMetaStaticFallback = ({
  apiLocale,
  keyword,
  locationName,
  categoryName,
  priceType,
}: SrpMetaStaticFallbackProps): SrpStaticMetadata => {
  if (priceType === FilterPriceType.GiveAway) {
    return apiLocale === API_LOCALE.fr_CA
      ? {
          title: keyword
            ? `Annonces gratuites pour ${keyword} dans ${categoryName} dans ${locationName} | Kijiji Marketplaces`
            : `Annonces gratuites dans ${categoryName} dans ${locationName} | Kijiji Marketplaces`,
          description: keyword
            ? `Trouvez des annonces gratuites pour ${keyword} dans ${categoryName} dans ${locationName}. Visitez Petites Annonces Kijiji pour acheter, vendre ou échanger presque tout! Trouvez des objets neufs et d'occasion, des voitures, des biens immobiliers, des emplois, des services, des locations de vacances et bien plus encore virtuellement dans ${locationName}.`
            : `Trouvez des annonces gratuites dans ${categoryName} dans ${locationName}. Visitez Petites Annonces Kijiji pour acheter, vendre ou échanger presque tout! Trouvez des objets neufs et d'occasion, des voitures, des biens immobiliers, des emplois, des services, des locations de vacances et bien plus encore virtuellement dans ${locationName}.`,
        }
      : {
          title: keyword
            ? `Free Listings for ${keyword} in ${categoryName} in ${locationName} | Kijiji Marketplaces`
            : `Free Listings in ${categoryName} in ${locationName} | Kijiji Marketplaces`,
          description: keyword
            ? `Find Free Listings for ${keyword} in ${categoryName} in ${locationName}. Visit Kijiji Classifieds to buy, sell, or trade almost anything! Find new and used items, cars, real estate, jobs, services, vacation rentals and more virtually in ${locationName}.`
            : `Find Free Listings in ${categoryName} in ${locationName}. Visit Kijiji Classifieds to buy, sell, or trade almost anything! Find new and used items, cars, real estate, jobs, services, vacation rentals and more virtually in ${locationName}.`,
        }
  }

  if (keyword) {
    return apiLocale === API_LOCALE.fr_CA
      ? {
          title: `${keyword} dans ${categoryName} dans ${locationName} | Kijiji Marketplaces`,
          description: `Trouvez ${keyword} dans ${categoryName} dans ${locationName}. Visitez Petites Annonces Kijiji pour acheter, vendre ou échanger presque tout! Trouvez des objets neufs et d'occasion, des voitures, des biens immobiliers, des emplois, des services, des locations de vacances et bien plus encore virtuellement dans ${locationName}.`,
        }
      : {
          title: `${keyword} in ${categoryName} in ${locationName} | Kijiji Marketplaces`,
          description: `Find ${keyword} in ${categoryName} in ${locationName}. Visit Kijiji Classifieds to buy, sell, or trade almost anything! Find new and used items, cars, real estate, jobs, services, vacation rentals and more virtually in ${locationName}.`,
        }
  }

  return apiLocale === API_LOCALE.fr_CA
    ? {
        title: `${categoryName} dans ${locationName} - Achetez, vendez et économisez avec le site de petites annonces locales n°1 au Canada`,
        description: `Parcourez ${categoryName} dans ${locationName}. Visitez Petites Annonces Kijiji pour acheter, vendre ou échanger presque tout! Trouvez des objets neufs et d'occasion, des voitures, des biens immobiliers, des emplois, des services, des locations de vacances et bien plus encore virtuellement dans ${locationName}.`,
      }
    : {
        title: `${categoryName} in ${locationName} - Buy, Sell & Save with Canada's #1 Local Marketplaces`,
        description: `Browse ${categoryName} in ${locationName}. Visit Kijiji Classifieds to buy, sell, or trade almost anything! Find new and used items, cars, real estate, jobs, services, vacation rentals and more virtually in ${locationName}.`,
      }
}
